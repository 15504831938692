import React, { useState } from 'react';
import { Button, Flex, Popconfirm, Space } from 'antd';
import { DeleteOutlined, EditFilled, SendOutlined } from '@ant-design/icons';

import { StatusEnum } from '@src/interfaces/common';
import { useBoundDetailPatientStore } from '@store/slices/detailsPatient';
import { useNavigate } from 'react-router-dom';
import EditPatient from '@components/EditPatient';

const ControlButtons = () => {
  const { patient, sendPollsByPatient, removePatient } = useBoundDetailPatientStore(
    ({ sendPollsByPatient, patient, removePatient }) => ({
      sendPollsByPatient,
      removePatient,
      patient,
    })
  );
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { isDelete, id, status } = patient;

  const onSoftDelete = () => {
    setConfirmLoading(true);

    removePatient(String(id))
      .then(() => {
        setConfirmLoading(false);
        setTimeout(() => {
          navigate('/main/patients');
        }, 200);
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  };

  const sendStatus = [StatusEnum.default, StatusEnum.inactive, StatusEnum.draft];
  return (
    <>
      {openEdit && (
        <EditPatient isOpen={openEdit} onClose={() => setOpenEdit(false)} patient={patient} />
      )}

      <Space>
        <Button
          icon={<EditFilled />}
          onClick={() => {
            setOpenEdit(true);
          }}
        >
          Редактировать
        </Button>

        {sendStatus.includes(status.id) && (
          <Button color="blue" type="primary" icon={<SendOutlined />} onClick={sendPollsByPatient}>
            Отправить
          </Button>
        )}

        {status.id === StatusEnum.active && !isDelete && (
          <Flex gap={12}>
            <Popconfirm
              title="Удалить"
              description="Вы точно хотите удалить пользователя?"
              okText="Да"
              cancelText="Нет"
              onConfirm={onSoftDelete}
              okButtonProps={{ loading: confirmLoading }}
            >
              <Button type="primary" icon={<DeleteOutlined />} danger>
                Удалить
              </Button>
            </Popconfirm>
          </Flex>
        )}
      </Space>
    </>
  );
};

export default ControlButtons;
